import { product1 } from "../../assets/imagesPath";

const singlepopulardata = [
  {
    id: 1,
    name: "HN-SS-NT1",
    description: "1/16″ NPT SS 316",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Fittings",
    slugUrl: "Baldota- Tube Fittings",
    type: "Fittings",
   tag: "recent-product"
  },
  {
    id: 2,
    name: "2 Baldota- Precision Pipe",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Fittings",
    slugUrl: "Baldota- Precision Pipe",
    type: "Fittings",
  },
  {
    id: 3,
    name: "3 Baldota- Weld Fittings",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Fittings",
    slugUrl: "Baldota- Weld Fittings",
    type: "Fittings",
    tag: "new-product"
  },
  {
    id: 4,
    name: "4 Baldota- Bolte Fittings",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Fittings",
    slugUrl: "Baldota- Bolte Fittings",
    type: "Fittings",
    tag: "recent-product"
  },
  {
    id: 5,
    name: "5 Baldota- Tap Fittings",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Fittings",
    slugUrl: "Baldota- Tap Fittings",
    type: "Fittings",
    tag: "new-product"
  },

  {
    id: 9,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
    tag: "recent-product"
  },
  {
    id: 10,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
  },
  {
    id: 11,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
    tag: "recent-product"
  },
  {
    id: 12,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
    tag: "new-product"
  },
  {
    id: 13,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
  },
  {
    id: 14,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
    tag: "new-product"
  },

  {
    id: 15,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
  },

  {
    id: 115,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
    tag: "new-product"
  },
  {
    id: 116,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
  },
  {
    id: 117,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
    tag: "recent-product"
  },
  {
    id: 118,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
  },
  {
    id: 119,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
    tag: "new-product"
  },
  {
    id: 120,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
  },
  {
    id: 121,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
  },
  {
    id: 122,
    name: "New Baldota- Tube Valves",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Valves",
    slugUrl: "Baldota- Weld Valves",
    type: "Valves",
  },


  {
    id: 16,
    name: "New Baldota- Tube Manifolds",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Manifolds",
    slugUrl: "Baldota- Weld Manifolds",
    type: "Manifolds",
  },
  {
    id: 17,
    name: "New Baldota- Tube Manifolds",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Manifolds",
    slugUrl: "Baldota- Weld Manifolds",
    type: "Manifolds",
    tag: "recent-product"
  },
  {
    id: 18,
    name: "New Baldota- Tube Manifolds",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Manifolds",
    slugUrl: "Baldota- Weld Manifolds",
    type: "Manifolds",
    tag: "new-product"
  },
  {
    id: 19,
    name: "New Baldota- Tube Manifolds",
    description: "1/16″ to 1 1/2″ and 2mm to 38mm",
    longDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    price: 2000,
    productImage: product1,
    offerPrice: 23,
    url: "/Manifolds",
    slugUrl: "Baldota- Weld Manifolds",
    type: "Manifolds",
    tag: "new-product"
  },
];

export default singlepopulardata;
