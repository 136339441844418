import React from "react";
import "../footer/footer.css";
import RoundBtn from "../cta/RoundBtn";
import { fooLogo } from "../../assets/imagesPath";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer id="footerSection">
      <div className="topSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 mx-auto">
              <div className="footerTopWrap">
                <div className="col-md-5">
                  <div className="leftTopWrap">
                    <h6>Contact us now and give your project a boost</h6>
                    <p>We are process instrumentation engineers</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <RoundBtn
                    rBtnLink="#"
                    rBtnName="Contact Us"
                    color="#fff"
                    fill="#fff"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="midSec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-10 mx-auto">
              <div className="row rowGap">
                <div className="col-lg-5 col-md-6">
                  <div className="wrap">
                    <div className="foologoWrap">
                      <Link to="">
                        <img
                          src={fooLogo}
                          alt="Baldota Logo"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <p className="commonPara">
                      Baldota Instruments and Fittings LLP is a family run
                      business that has been built on creative energy and a long
                      tradition of helping people and serving many industries
                      including Oil & Gas, Medical, Automotive, and Mechanical.
                    </p>
                    <ul className="socialLinksWrap">
                      <li>
                        <Link to="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <circle cx="12" cy="12" r="11.5" stroke="#FFA700" />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6.54309 7.46877C6.54309 7.22388 6.63702 6.98902 6.80421 6.81586C6.9714 6.64269 7.19816 6.54541 7.43461 6.54541H16.3216C16.4388 6.54521 16.5549 6.56895 16.6632 6.61527C16.7715 6.66159 16.8699 6.72959 16.9528 6.81535C17.0357 6.90112 17.1015 7.00298 17.1463 7.1151C17.1911 7.22723 17.2142 7.34741 17.2141 7.46877V16.6732C17.2142 16.7946 17.1913 16.9148 17.1465 17.027C17.1017 17.1391 17.036 17.2411 16.9532 17.3269C16.8703 17.4128 16.7719 17.4809 16.6637 17.5273C16.5554 17.5737 16.4393 17.5976 16.3221 17.5975H7.43461C7.31749 17.5975 7.20152 17.5736 7.09333 17.5272C6.98513 17.4808 6.88683 17.4127 6.80404 17.3269C6.72125 17.2411 6.65559 17.1393 6.61081 17.0272C6.56604 16.9151 6.54303 16.795 6.54309 16.6737V7.46877ZM10.7669 10.7593H12.2118V11.5108C12.4204 11.0788 12.954 10.69 13.7557 10.69C15.2928 10.69 15.6571 11.5505 15.6571 13.1295V16.0543H14.1016V13.4892C14.1016 12.5899 13.893 12.0825 13.3633 12.0825C12.6285 12.0825 12.3229 12.6296 12.3229 13.4892V16.0543H10.7669V10.7593ZM8.09912 15.9854H9.65515V10.69H8.09912V15.9849V15.9854ZM9.87779 8.96281C9.88072 9.1008 9.85701 9.23801 9.80806 9.36638C9.7591 9.49474 9.68588 9.61169 9.59269 9.71036C9.49951 9.80902 9.38823 9.88741 9.26539 9.94093C9.14256 9.99445 9.01064 10.022 8.87738 10.022C8.74412 10.022 8.6122 9.99445 8.48936 9.94093C8.36653 9.88741 8.25525 9.80902 8.16206 9.71036C8.06888 9.61169 7.99566 9.49474 7.9467 9.36638C7.89774 9.23801 7.87404 9.1008 7.87697 8.96281C7.88273 8.69196 7.99066 8.43422 8.17764 8.24477C8.36463 8.05532 8.6158 7.94924 8.87738 7.94924C9.13895 7.94924 9.39013 8.05532 9.57711 8.24477C9.7641 8.43422 9.87203 8.69196 9.87779 8.96281Z"
                              fill="#FFA700"
                            />
                          </svg>
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <circle
                              cx="12.6274"
                              cy="12"
                              r="11.5"
                              stroke="#FFA700"
                            />
                            <path
                              d="M19.5019 7.83943C19.0084 8.0581 18.4782 8.20585 17.9209 8.27263C18.496 7.92854 18.9262 7.38699 19.1313 6.74902C18.591 7.06993 17.9997 7.29582 17.3831 7.41686C16.9685 6.97412 16.4192 6.68066 15.8207 6.58205C15.2222 6.48344 14.6078 6.58519 14.073 6.87151C13.5383 7.15783 13.113 7.61269 12.8632 8.16548C12.6134 8.71828 12.5532 9.33806 12.6917 9.92863C11.597 9.87366 10.5261 9.58912 9.54842 9.09348C8.57078 8.59784 7.70828 7.90217 7.0169 7.05162C6.7805 7.45941 6.64456 7.93221 6.64456 8.43575C6.6443 8.88905 6.75593 9.3354 6.96955 9.73521C7.18316 10.135 7.49216 10.4759 7.86913 10.7277C7.43195 10.7138 7.00441 10.5956 6.62211 10.3831V10.4186C6.62206 11.0543 6.84198 11.6705 7.24454 12.1626C7.6471 12.6547 8.20752 12.9924 8.83069 13.1183C8.42513 13.228 7.99994 13.2442 7.58722 13.1656C7.76304 13.7126 8.10552 14.191 8.56673 14.5337C9.02793 14.8764 9.58476 15.0663 10.1593 15.0769C9.18401 15.8425 7.97958 16.2577 6.73972 16.2559C6.52009 16.256 6.30065 16.2432 6.08252 16.2175C7.34105 17.0267 8.80607 17.4561 10.3023 17.4545C15.3672 17.4545 18.1361 13.2595 18.1361 9.6213C18.1361 9.5031 18.1331 9.38372 18.1278 9.26552C18.6664 8.87603 19.1313 8.39373 19.5007 7.8412L19.5019 7.83943Z"
                              fill="#FFA700"
                            />
                          </svg>
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <circle
                              cx="12.2583"
                              cy="12"
                              r="11.5"
                              stroke="#FFA700"
                            />
                            <path
                              d="M15.4664 12.4035L15.8494 9.89778H13.455V8.27172C13.455 7.58634 13.7894 6.91783 14.8618 6.91783H15.9503V4.78466C15.9503 4.78466 14.9627 4.61548 14.0182 4.61548C12.0464 4.61548 10.7576 5.81576 10.7576 7.98831V9.89821H8.56567V12.4039H10.7576V18.4616H13.455V12.4039L15.4664 12.4035Z"
                              fill="#FFA700"
                            />
                          </svg>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="wrap">
                    <h6 className="sectionTitle">Product</h6>
                    <ul className="fooLinks">
                      <li>
                        <Link to="/categories/fittings">Fittings</Link>
                      </li>
                      <li>
                        <Link to="/categories/valves">Valves</Link>
                      </li>
                      <li>
                        <Link to="/categories/manifolds">Manifolds</Link>
                      </li>
                      <li>
                        <Link to="">Condensate Pots</Link>
                      </li>
                      <li>
                        <Link to="">Cylinders</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="wrap">
                    <h6 className="sectionTitle">Company</h6>
                    <ul className="fooLinks">
                      <li>
                        <Link to="">About Us</Link>
                      </li>
                      <li>
                        <Link to="">News</Link>
                      </li>
                      <li>
                        <Link to="">Careers</Link>
                      </li>
                      <li>
                        <Link to="">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="wrap">
                    <h6 className="sectionTitle">Corporate Office</h6>
                    <ul className="fooLinks">
                      <li className="d-flex gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="22"
                            viewBox="0 0 18 22"
                            fill="none"
                          >
                            <path
                              d="M9.00063 5.37231C8.29251 5.37231 7.6003 5.5823 7.01152 5.97571C6.42274 6.36911 5.96384 6.92828 5.69285 7.5825C5.42187 8.23671 5.35097 8.95659 5.48911 9.65111C5.62726 10.3456 5.96825 10.9836 6.46897 11.4843C6.96968 11.985 7.60763 12.326 8.30215 12.4641C8.99666 12.6023 9.71654 12.5314 10.3708 12.2604C11.025 11.9894 11.5841 11.5305 11.9775 10.9417C12.371 10.353 12.5809 9.66074 12.5809 8.95262C12.5809 8.00307 12.2037 7.0924 11.5323 6.42096C10.8609 5.74952 9.95019 5.37231 9.00063 5.37231ZM9.00063 10.7428C8.64657 10.7428 8.30046 10.6378 8.00607 10.4411C7.71168 10.2444 7.48223 9.96479 7.34674 9.63768C7.21125 9.31058 7.1758 8.95064 7.24487 8.60338C7.31395 8.25613 7.48444 7.93715 7.7348 7.68679C7.98516 7.43644 8.30413 7.26594 8.65139 7.19687C8.99864 7.12779 9.35858 7.16324 9.68569 7.29874C10.0128 7.43423 10.2924 7.66368 10.4891 7.95807C10.6858 8.25246 10.7908 8.59856 10.7908 8.95262C10.7908 9.4274 10.6022 9.88273 10.2665 10.2185C9.93074 10.5542 9.47541 10.7428 9.00063 10.7428Z"
                              fill="#FFA700"
                            />
                            <path
                              d="M9.00037 21.4841C8.24666 21.488 7.503 21.3112 6.83165 20.9686C6.1603 20.626 5.58081 20.1275 5.14169 19.515C1.73055 14.8095 0.000366211 11.2722 0.000366211 9.00049C0.000366211 6.61354 0.948578 4.32435 2.6364 2.63653C4.32423 0.9487 6.61342 0.000488281 9.00037 0.000488281C11.3873 0.000488281 13.6765 0.9487 15.3643 2.63653C17.0522 4.32435 18.0004 6.61354 18.0004 9.00049C18.0004 11.2722 16.2702 14.8095 12.859 19.515C12.4199 20.1275 11.8404 20.626 11.1691 20.9686C10.4977 21.3112 9.75407 21.488 9.00037 21.4841ZM9.00037 1.95444C7.13182 1.95657 5.34041 2.6998 4.01915 4.02106C2.69788 5.34232 1.95466 7.13373 1.95253 9.00228C1.95253 10.8014 3.64691 14.1284 6.72239 18.3702C6.98348 18.7298 7.32601 19.0225 7.72194 19.2243C8.11787 19.4261 8.55596 19.5313 9.00037 19.5313C9.44477 19.5313 9.88286 19.4261 10.2788 19.2243C10.6747 19.0225 11.0172 18.7298 11.2783 18.3702C14.3538 14.1284 16.0482 10.8014 16.0482 9.00228C16.0461 7.13373 15.3028 5.34232 13.9816 4.02106C12.6603 2.6998 10.8689 1.95657 9.00037 1.95444Z"
                              fill="#FFA700"
                            />
                          </svg>
                        </span>
                        <p>
                          1/41 Rocky Industrial Estate, I.B.Patel Road, Goregaon
                          (East), Mumbai- 400063.
                        </p>
                      </li>
                      <li>
                        <Link
                          to="mailto:instrumentindia@doublelok.com"
                          className="d-flex gap-3"
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_270_47)">
                                <path
                                  d="M9.00024 0C6.61408 0.00258081 4.32639 0.951621 2.63912 2.63889C0.951856 4.32616 0.00281599 6.61384 0.000235182 9C-0.0942648 16.1797 8.36948 20.5717 14.1752 16.3627C14.2587 16.3073 14.3301 16.2356 14.3854 16.152C14.4407 16.0684 14.4787 15.9746 14.4971 15.8761C14.5155 15.7776 14.514 15.6765 14.4926 15.5786C14.4713 15.4807 14.4306 15.388 14.3728 15.3061C14.3151 15.2242 14.2415 15.1547 14.1565 15.1017C14.0714 15.0487 13.9766 15.0133 13.8777 14.9976C13.7787 14.9819 13.6776 14.9861 13.5803 15.0101C13.483 15.0341 13.3915 15.0773 13.3112 15.1372C8.47523 18.642 1.42524 14.9835 1.50024 9C1.91199 -0.9495 16.09 -0.94725 16.5002 9V10.5C16.5002 10.8978 16.3422 11.2794 16.0609 11.5607C15.7796 11.842 15.3981 12 15.0002 12C14.6024 12 14.2209 11.842 13.9396 11.5607C13.6583 11.2794 13.5002 10.8978 13.5002 10.5V9C13.3112 3.05325 4.68849 3.054 4.50024 9C4.50897 9.912 4.79383 10.7999 5.31727 11.5468C5.84072 12.2937 6.57819 12.8644 7.43247 13.1838C8.28676 13.5032 9.21778 13.5562 10.1028 13.3359C10.9878 13.1156 11.7854 12.6323 12.3902 11.9497C12.7156 12.524 13.2212 12.9749 13.8288 13.2326C14.4364 13.4904 15.112 13.5406 15.751 13.3754C16.39 13.2103 16.9567 12.8391 17.3633 12.3192C17.77 11.7994 17.9938 11.16 18.0002 10.5V9C17.9977 6.61384 17.0486 4.32616 15.3613 2.63889C13.6741 0.951621 11.3864 0.00258081 9.00024 0ZM9.00024 12C8.20459 12 7.44152 11.6839 6.87892 11.1213C6.31631 10.5587 6.00024 9.79565 6.00024 9C6.00024 8.20435 6.31631 7.44129 6.87892 6.87868C7.44152 6.31607 8.20459 6 9.00024 6C9.79589 6 10.5589 6.31607 11.1216 6.87868C11.6842 7.44129 12.0002 8.20435 12.0002 9C12.0002 9.79565 11.6842 10.5587 11.1216 11.1213C10.5589 11.6839 9.79589 12 9.00024 12Z"
                                  fill="#FFA700"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_270_47">
                                  <rect
                                    width="18"
                                    height="18"
                                    fill="white"
                                    transform="translate(0.000366211)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          instrumentindia@doublelok.com
                        </Link>
                      </li>
                      <li className="d-flex gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M1.37265 1.3627L2.16002 0.680412C3.06737 -0.226804 4.53714 -0.226804 5.44449 0.680412C5.46699 0.702905 6.85427 2.50984 6.85427 2.50984C7.70913 3.40956 7.70913 4.82662 6.85427 5.71884L5.98441 6.8135C7.07923 9.29522 8.78146 11.0047 11.1811 12.0244L12.2759 11.1471C13.1683 10.2849 14.593 10.2849 15.4854 11.1471C15.4854 11.1471 17.2926 12.5342 17.3151 12.5567C18.2224 13.4639 18.2224 14.9335 17.3526 15.8032L16.6027 16.6654C15.7403 17.5276 14.578 18 13.3182 18C7.58915 18 0.000366211 10.4049 0.000366211 4.68416C0.000366211 3.43205 0.47279 2.26242 1.37265 1.3702V1.3627ZM13.3182 16.493C14.1731 16.493 14.9605 16.1781 15.5004 15.6307L16.2503 14.7685C16.5577 14.4611 16.5727 13.9588 16.2803 13.6364C16.2803 13.6364 14.488 12.2568 14.4655 12.2343C14.1581 11.9269 13.6182 11.9269 13.3032 12.2343C13.2807 12.2568 11.7735 13.4639 11.7735 13.4639C11.5635 13.6289 11.2861 13.6739 11.0386 13.5764C7.93409 12.3918 5.70695 10.1724 4.42466 6.97095C4.32717 6.72352 4.36467 6.43861 4.53714 6.22118C4.53714 6.22118 5.74444 4.70665 5.75944 4.69166C6.08189 4.36926 6.08189 3.85192 5.75944 3.52952C5.73695 3.50703 4.35717 1.71509 4.35717 1.71509C4.03472 1.42268 3.5323 1.43018 3.18736 1.77507L2.39998 2.45736C1.82257 3.03468 1.50013 3.82193 1.50013 4.67666C1.50013 9.89503 8.82645 16.493 13.3182 16.493Z"
                              fill="#FFA700"
                            />
                          </svg>
                        </span>
                        <div className="d-flex flex-column">
                          <Link to="tel:022-26853293">022-26853293,</Link>
                          <Link to="tel:+919322217622">+91 9322217622</Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyWriteWrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-10 mx-auto">
              <div className="copyRow">
                <p>© 2024 Baldota. All Rights Reserved</p>
                <p>
                  Crafted by :{" "}
                  <Link
                    to="https://version-next.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Version Next Technologies Pvt. Ltd
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
