import React from "react";
import "../cta/cta.css";
function SquareBtn({ btnBgColor, color, sBtnName, className, onClick }) {
  return (
    <button
      className="actionBtn"
      style={{ backgroundColor: btnBgColor, color: color }}
      onClick={onClick}
    >
      <span className={className}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          className="hamburger"
        >
          <path
            d="M2 2H19"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M2 10H19"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M2 18H19"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          className="cartIco"
        >
          <path
            d="M5.07606 16.5259C5.79952 16.5259 6.386 15.9394 6.386 15.2159C6.386 14.4925 5.79952 13.906 5.07606 13.906C4.35259 13.906 3.76611 14.4925 3.76611 15.2159C3.76611 15.9394 4.35259 16.5259 5.07606 16.5259Z"
            fill="black"
          />
          <path
            d="M11.6257 16.5259C12.3492 16.5259 12.9357 15.9394 12.9357 15.2159C12.9357 14.4925 12.3492 13.906 11.6257 13.906C10.9023 13.906 10.3158 14.4925 10.3158 15.2159C10.3158 15.9394 10.9023 16.5259 11.6257 16.5259Z"
            fill="black"
          />
          <path
            d="M15.5555 2.7718H14.2456V1.46186C14.2456 1.28815 14.1766 1.12155 14.0537 0.998721C13.9309 0.87589 13.7643 0.806885 13.5906 0.806885C13.4169 0.806885 13.2503 0.87589 13.1275 0.998721C13.0046 1.12155 12.9356 1.28815 12.9356 1.46186V2.7718H11.6257C11.452 2.7718 11.2854 2.8408 11.1625 2.96363C11.0397 3.08647 10.9707 3.25306 10.9707 3.42677C10.9707 3.60048 11.0397 3.76707 11.1625 3.8899C11.2854 4.01273 11.452 4.08174 11.6257 4.08174H12.9356V5.39168C12.9356 5.56539 13.0046 5.73199 13.1275 5.85482C13.2503 5.97765 13.4169 6.04665 13.5906 6.04665C13.7643 6.04665 13.9309 5.97765 14.0537 5.85482C14.1766 5.73199 14.2456 5.56539 14.2456 5.39168V4.08174H15.5555C15.7292 4.08174 15.8958 4.01273 16.0186 3.8899C16.1415 3.76707 16.2105 3.60048 16.2105 3.42677C16.2105 3.25306 16.1415 3.08647 16.0186 2.96363C15.8958 2.8408 15.7292 2.7718 15.5555 2.7718Z"
            fill="black"
          />
          <path
            d="M14.7506 7.17689C14.6659 7.16104 14.5789 7.16216 14.4947 7.18019C14.4104 7.19821 14.3306 7.23277 14.2598 7.28187C14.189 7.33098 14.1286 7.39365 14.0823 7.46624C14.0359 7.53884 14.0043 7.61993 13.9895 7.7048C13.9078 8.15814 13.6695 8.56837 13.3161 8.86383C12.9627 9.15929 12.5167 9.3212 12.056 9.32127H4.03984L3.42417 4.0815H9.00584C9.17955 4.0815 9.34614 4.01249 9.46897 3.88966C9.5918 3.76683 9.66081 3.60023 9.66081 3.42653C9.66081 3.25282 9.5918 3.08622 9.46897 2.96339C9.34614 2.84056 9.17955 2.77155 9.00584 2.77155H3.2696L3.24209 2.541C3.18565 2.06327 2.95591 1.62284 2.59641 1.30318C2.23692 0.983518 1.77265 0.806846 1.29159 0.806641L1.14618 0.806641C0.972473 0.806641 0.805878 0.875646 0.683048 0.998477C0.560217 1.12131 0.491211 1.2879 0.491211 1.46161C0.491211 1.63532 0.560217 1.80192 0.683048 1.92475C0.805878 2.04758 0.972473 2.11658 1.14618 2.11658H1.29159C1.45201 2.1166 1.60685 2.1755 1.72673 2.2821C1.84661 2.38871 1.9232 2.5356 1.94197 2.69492L2.84321 10.3581C2.93678 11.155 3.31969 11.8899 3.91927 12.4232C4.51885 12.9565 5.29336 13.2511 6.0958 13.2511H12.9357C13.1094 13.2511 13.276 13.1821 13.3988 13.0593C13.5216 12.9364 13.5906 12.7698 13.5906 12.5961C13.5906 12.4224 13.5216 12.2558 13.3988 12.133C13.276 12.0102 13.1094 11.9412 12.9357 11.9412H6.0958C5.6894 11.9412 5.29298 11.8153 4.96113 11.5807C4.62929 11.3461 4.37835 11.0144 4.24289 10.6312H12.056C12.8238 10.6313 13.5671 10.3616 14.1562 9.86936C14.7454 9.37709 15.1428 8.69348 15.2792 7.93797C15.2944 7.85328 15.2929 7.76642 15.2746 7.68233C15.2563 7.59824 15.2217 7.51858 15.1726 7.4479C15.1235 7.37722 15.061 7.31691 14.9886 7.27041C14.9161 7.22391 14.8353 7.19213 14.7506 7.17689Z"
            fill="black"
          />
        </svg>
      </span>
      {sBtnName}
    </button>
  );
}

export default SquareBtn;
